<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-row>
        <el-col :span="8">
          <el-form-item label="手机号：">
            <el-input
              v-model="formInline.mobile"
              v-check-permission
              placeholder="请输入手机号"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="邮箱：">
            <el-input
              v-model="formInline.email"
              v-check-permission
              placeholder="请输入邮箱"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" @click="queryData()"> 查询 </el-button>
            <el-button
              v-if="$checkPermission('ADDPHARMACISTBIND', $route)"
              type="primary"
              @click="addBind()"
            >
              新增绑定
            </el-button>
            <el-button type="primary" @click="queryClear()"> 重置 </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column
        prop="operationUserId"
        label="管理后台用户ID"
        align="center"
      />
      <el-table-column
        prop="operationMobile"
        label="管理后台用户手机号"
        align="center"
      />
      <el-table-column
        prop="operationEmail"
        label="管理后台用户邮箱"
        align="center"
      />
      <el-table-column prop="doctorId" label="医生信息ID" align="center" />
      <el-table-column prop="doctorMobile" label="医生手机号" align="center" />
      <el-table-column prop="doctorName" label="医生姓名" align="center" />
      <el-table-column prop="createBy" label="操作人" align="center" />
      <el-table-column prop="createTime" label="创建时间" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('CANCELBIND', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row)"
          >
            解绑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="解绑" :visible.sync="dialogVisible" width="35%">
      <h3 style="margin-bottom: 20px; text-align: center">
        确认{{ accountInfo.doctorName }}账号解绑吗？
      </h3>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDel()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import {  mapState } from 'vuex';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      accountInfo: {},
      loading: false,
      dialogVisible: false, // 弹框显示与否
      titleType: '', // 是新增还是编辑
      formInline: {
        email: '',
        mobile: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    // ...mapState('basicDictionary', {
    //   occupationList: state => state.occupationList
    // }),
  },
  watch: {},
  mounted() {
    this.getPharmacistBindList();
  },
  methods: {
    // 新增绑定
    addBind() {
      this.$router.push({
        path: '/doctorManage/addBind',
      });
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getPharmacistBindList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getPharmacistBindList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        mobile: '',
        email: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getPharmacistBindList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getPharmacistBindList();
    },
    // 分页查询问诊类型列表
    getPharmacistBindList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      if (this.formInline.occupationCode === 'all') {
        delete params.occupationCode;
      }
      this.$api
        .pharmacistPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item) {
      this.dialogVisible = true;
      this.accountInfo = item;
    },
    // 保存职业
    confirmDel() {
      const param = {
        id: this.accountInfo.id,
      };
      this.$api.pharmacistUnBind(param).then((res) => {
        if (res.code === 0) {
          this.dialogVisible = false;
          this.$message.success('删除药师成功');
          this.getPharmacistBindList();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
